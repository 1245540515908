/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Dispatch, ReactNode, SetStateAction, createContext, useState, useContext } from 'react';
import { CreateAccountContext } from './create_account_context';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

type SelectedCustomer = {
  maskedRewardsId: string;
  rewardsLinkingId: string;
  custLookupRewardsLinkingId: string;
  emailAddress: string;
};

type RewardsProviderType = {
  openRewardsFindId: boolean;
  openRewardsEnterId: boolean;
  ready: boolean;
  selectedCustomer: SelectedCustomer;
  showRewardsOptions: boolean;
  showRewardsId: boolean;
  setOpenRewardsFindId: Dispatch<SetStateAction<boolean>>;
  setOpenRewardsEnterId: Dispatch<SetStateAction<boolean>>;
  setReady: Dispatch<SetStateAction<boolean>>;
  setSelectedCustomer: Dispatch<SetStateAction<SelectedCustomer>>;
  onClose: () => void;
};

export const RewardsContext = createContext<RewardsProviderType>({} as RewardsProviderType);

type RewardsProviderProps = {
  children: ReactNode;
};

export function RewardsProvider({ children }: RewardsProviderProps) {
  const isMxSite = useLocale() === countryCodes.mx;
  const [openRewardsFindId, setOpenRewardsFindId] = useState(false);
  const [openRewardsEnterId, setOpenRewardsEnterId] = useState(false);
  const [ready, setReady] = useState(false);
  const { rewardsId, maskedRewardsId } = useContext(CreateAccountContext);
  const showRewardsOptions = isMxSite || !!maskedRewardsId || !!rewardsId;
  const showRewardsId = !maskedRewardsId && !rewardsId;
  const [selectedCustomer, setSelectedCustomer] = useState({
    maskedRewardsId: '',
    rewardsLinkingId: '',
    custLookupRewardsLinkingId: '',
    emailAddress: '',
  });

  function onClose() {
    setOpenRewardsFindId(false);
    setReady(false);
  }

  return (
    <RewardsContext.Provider
      value={{
        openRewardsFindId,
        openRewardsEnterId,
        ready,
        selectedCustomer,
        showRewardsOptions,
        showRewardsId,
        setOpenRewardsFindId,
        setOpenRewardsEnterId,
        setReady,
        setSelectedCustomer,
        onClose,
      }}
    >
      {children}
    </RewardsContext.Provider>
  );
}

export default RewardsProvider;
