/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import styles from './styles.module.scss';
import { Spinner } from '../Spinner';

type LoadingIndicatorProps = {
  size?: number;
};

export const LoadingIndicator = ({ size }: LoadingIndicatorProps) => {
  return <Spinner className={styles.loadingIndicator} size={size ?? 100} />;
};
