/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useFeatureFlag } from '@/features/globalConfig';
import { useAppState } from '@/stores/AppState';
import { useIsValidMobileAppVersion } from '@/hooks/useIsValidMobileAppVersion';
import { useStreamLineSignIn } from './useStreamLineSignIn';

/**Returns a single value to enable or disable OTP feature(s):
 *- Feature is enabled by `OTP_ENABLED` global config
 *- If DIY App and OTP is enabled for DIY App by `OTP_ENABLED_APP` global config
 *- US Only by locale
 *
 *This function always returns true IFF the server is running in development environment
 *
 * TODO: Remove the if block once OTP feature is delivered
 *
 * @returns boolean value of the conditions above
 */
export const useIsOtpFeatureEnabled = () => {
  const isValidMobileAppVersion = useIsValidMobileAppVersion('MOBILE_APP_STREAMLINED_FLOW_VERSION');
  const locale = useLocale();
  const isUS = locale === countryCodes.enUS;
  const isMX = locale === countryCodes.mx;
  const { isMobileApp } = useAppState().state;

  const isOtpFeatureEnabled = useFeatureFlag('OTP_ENABLED') === 'true';

  const isOtpFeatureEnabledForMobileApp = useFeatureFlag('OTP_ENABLED_APP') === 'true';
  const isStreamlineSigninEnabled = useStreamLineSignIn();

  // Note: this is experimental and we may revert this in the future
  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  return (
    isOtpFeatureEnabled &&
    (isUS || (isStreamlineSigninEnabled && isMX)) &&
    ((isMobileApp && isOtpFeatureEnabledForMobileApp && !!isValidMobileAppVersion) || !isMobileApp)
  );
};
